const urls = {
  login: "/",
  dashboard: "/dashboard",
  clients: "/clients",
  profile: "/profile/edit",
  createStrategy: "/create-strategy",
  myStrategy: "/my-strategy",
  editStrategy: "/edit-strategy/:id",
  copyStrategy: "/copy-strategy/:id",
  createPortfolio: "/create-portfolio",
  editPortfolio: "/edit-portfolio/:id",
  copyPortfolio: "/copy-portfolio/:id",
  openRequest: "/open-request",
  BasketEdit: "/basketEdit",
  myPortfolio: "/my-portfolio",
  clientPortfolio: "/client/portfolio",
  viewMyPortfolio: "/my-portfolio/view",
  orderPlacement: "/order-placement",
  openPosition: "/open-position",
  summary: "/summary",
  myOrderBook: "/my-orderbook",
  VirtualOrderBook: "/virtual-orderbook",
  FilterStrategy: "/client-strategytable",
  FilterPortfolio: "/client-portfoliotable",
  Graph: "/summary-graph",
  SubscriptionTable: "/subscription",
  SubscriptionTabs:"/SubscriptionTabs",
  strategySummary: "/strategy-summary",
  portfolioSummary: "/portfolio-summary",
  PendingOrders: "/Pendingorders",
  ClientStrategy: "/clientstrategy",
  PositionMapping: "/positionmapping",
  ErrorOrders:"/Errororders",
  P_and_L:"/P&L",
  notifications:"/notifications",
  broker:"/broker",
  Clientbasket:"/create-Clientbasket",
  userbasket:"/Userbasket",
  marketplace:"/Market-Place",
  strategydetails:"/strategy-details",
  tradehistory:"/Trade-history",
  reports:"/Reports"
};

export default urls;
