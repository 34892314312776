import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    fontFamily: "lato",
  },
  control: {
    height: "16px",
    width: "16px",
    padding: 2,
    borderRadius: 4,
    border: "1.5px solid",
    borderColor: "gray",
    backgroundColor: "#ffffff",
    color: "black",
    _checked: {
      borderColor: "#000",
      backgroundColor: "#ffffff",
      color: "black",
      _hover: {
        backgroundColor: "#ffffff",
        borderColor: "#000",
      },
    },
    _hover: {
      backgroundColor: "#ffffff",
    },
    _focus: {
      outline: "none",
      backgroundColor: "#ffffff",
    },
  },
});

export const checkboxTheme = defineMultiStyleConfig({ baseStyle });
