// import async from "react-select/dist/declarations/src/async";
import instance, { request } from "utils/interceptor";

export const notification = async () => {
  try {
    // let res = await instance.get("/api/v1/strategies/?size=500");
    let res = await instance.get("/api/v2/notifications/?notification_type=SUBSCRIPTION-REQ");

    return res;
  } catch (error) {
    throw error;
  }
};
export const subscriptionList = async (page,condition) => {
  
  try {
    // let res = await instance.get("/api/v1/strategies/?size=500");
    let res;
    if(condition===2){
       res = await instance.get(`/api/v2/subscriptions?page=${page}&approve_status=accept`);
    }else if(condition===3){
      res = await instance.get(`/api/v2/subscriptions?page=${page}&approve_status=reject`);
    }else{
      res = await instance.get(`/api/v2/subscriptions?seen=0&page=${page}`);
    }
   

    return res;
  } catch (error) {
    throw error;
  }
};

export const notificationcountapi = async () => {
  try {
    // let res = await instance.get("/api/v1/strategies/?size=500");
    let res = await instance.get("/api/v2/notifications/notification-count");

    return res;
  } catch (error) {
    throw error;
  }
};

export const seenNotification=async()=>{
  try {
    // let res = await instance.get("/api/v1/strategies/?size=500");
    let res = await instance.put("/api/v2/notifications/notification_seen");

    return res;
  } catch (error) {
    throw error;
  }
}

export const subscriptionStatus = async (query, data) => {
  try {
    let res = await instance.post(
      `/api/v2/subscriptions/approved?approve_status=${query}`,
      data
    );
    return res;
  } catch (error) {
    throw error;
  }
};

