import {

  Grid,
  GridItem,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";

import { UploadCSV } from "apis/uploadCSV";
import CloseIcon from "assets/icons/close";
import FormButton from "components/controls/Button";

import { useRef, useState } from "react";


const CSVUpload = (props) => {
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const { isOpen, onClose } = props;
  const toast = useToast();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDownload= () => {
    const data = [
      {
        Client: "ABII05",
        Multiplier: "3",
        Product: "kEEVSTRATEGY",
        Dealer1: "AMIT",
        Dealer2: "MANOJ",
      },
      {
        Client: "ABII06",
        Multiplier: "5",
        Product: "NIFTYE",
        Dealer1: "VIVEK",
        Dealer2: "AKSHAY",
      },
    ];

    const headers = {
      Client: "Clients",
      Multiplier: "Multiplier",
      Product: "Product",
      Dealer1: "Dealer1",
      Dealer2: "Dealer2",
    };

    

    const csv = [
      Object.values(headers).join(","),
      ...data.map((item) => Object.values(item).join(",")),
    ].join("\n");

    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    element.setAttribute("download", "example format Data");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };



  const handleSubmit = async () => {
    const formData = {
      file_upload: selectedFile,
    };

    // Make an API call to submit the file
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    if (formData) {
      UploadCSV(formData, "post", headers)
        .then((res) => {
          toast({
            title: "File uploaded Successfully",
            status: "success",
            position: "top-right",
            isClosable: true,
          });
          setIsDownloadLoading(false)
          onClose();
        })
        .catch((error) => {
          console.error("Error uploading file", error);
          toast({
            title: error.message,
            status: "error",
            position: "top-right",
            isClosable: true,
          });
          setIsDownloadLoading(false)
        });
    }
  };
  let primary_color = localStorage.getItem("primary_color") ||  process.env.REACT_APP_PRIMARY_COLOR;
  let secondary_color = localStorage.getItem("secondary_color") || process.env.REACT_APP_SECONDARY_COLOR;
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={"2xl"}>
      <ModalOverlay />

      <ModalContent overflow={"hidden"}  bg="white">
        <ModalHeader
          fontSize={22}
          fontWeight={600}
          fontFamily="Poppins"
          // bg={process.env.REACT_APP_PRIMARY_COLOR}
          bg={"brand.900" }
          color="brand.500"
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
          p={4}
        >
          Upload CSV
          <ModalCloseButton position={"relative"} top={0} right={0}>
            <CloseIcon />
          </ModalCloseButton>
        </ModalHeader>
        <ModalBody p={4}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <GridItem w="100%" colSpan={2}>
              <HStack>
                <FormButton
                  text="Download Example Format"
                  onClick={() => {
                    handleDownload();
                    
                    
                  }}
                  // isLoading={isDownloadLoading}
                  // loadingText={isDownloadLoading ? "Downloading..." : "Download"}
                />
              </HStack>
            </GridItem>
            <GridItem w="100%" colSpan={2}>
              CSV Upload Box
            </GridItem>
            <GridItem w="100%" colSpan={2}>
              <HStack>
                <Input
                  type="file"
                  accept=".csv"
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                  onClick={() => {
                    // Clear the file input value when clicked to allow selecting the same file multiple times
                    fileInputRef.current.value = null;
                  }}
                />
                <FormButton text="Submit" onClick={()=>{
                  handleSubmit(); setIsDownloadLoading(true)}}
                 
                 isLoading={isDownloadLoading}
                  loadingText={isDownloadLoading ? "Submiting..." : "Submit"}
                 />
              </HStack>
            </GridItem>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CSVUpload;
