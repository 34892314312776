import { data } from "utils/data";
import instance, { request } from "utils/interceptor";
import instance1 from "utils/interceptor1";
export const getTotalEarningsGraph = async (data) => {
 
  try {
    const res = await instance1.post(
    
      `/api/v1/reports/analysis/detail-pnl`,data
    );
    return res?.data;
  } catch (error) {
    return error.response;
  }
};

export const getTradeSummary = async (data) => {
 
  try {
    const res = await instance1.post(
    
      `/api/v1/reports/open-position/pnl`,data
    );
    return res?.data;
  } catch (error) {
    return error.response;
  }
};
// /api/v2/clients/clientpl_overall?email=bhavya.keev@gmail.com
export const totalvaluesingraph = async (id = "") => {
  try {
    const res = await instance.get(
     
      `api/v2/clients/clientpl_overall?email=${id}`
    );
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const fundwisestrategy = async (id = "") => {
  try {
    const res = await instance1.get(
     
      `/api/v1/premium-strategies/strategy/get-fundmanagerby-strategies`
    );
    return res.data;
  } catch (error) {
    return error.response;
  }
};
// /api/v1/premium-strategies/strategy/get-fundmanagerby-strategies
export const getDailyPL = async (id = "", length = "365", getdate,name) => {
 
  const { start_date, end_date } = getdate;

  let url = ``;
  if (start_date !== undefined && end_date !== undefined) {
    url = url + "&";
    url = url + `start_date=${start_date}&end_date=${end_date}`;
    length = "";
  }
  try {
    const res = await instance.get(
      // `api/v2/clients/earnings?email=${id}&day=${length}${url}`
      name === "client"
          ? `api/v2/clients/earnings?email=${id}&day=${length}${url}`
          : name === "strategy"
          ? `api/v2/strategies/earnings?strategy_name=${id}&day=${length}${url}`
          : `api/v2/portfolios/earnings?portfolio_name=${id}&day=${length}${url}`
    );
    // api/v2/portfolios/earnings/?day=29&portfolio_name=Portfolio%201x%20for%20SET%2013
    return res;
  } catch (error) {
    throw error;
  }
};

export const getDailyEarning = async (id = "", length = "365") => {
  try {
    const res = await instance.get(
      `api/v2/clients/earnings?email=${id}&day=${length}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const customEarning = async (email,startdate,enddate) => {
  try {
    const res = await instance.get(
      `api/v2/clients/earnings?email=${email}&start_date=${startdate}&end_date=${enddate}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const customtransaction=async (email,startdate,enddate)=>{
  try {
    const res = await instance.get(
      `api/v2/orders/dashboard?email=${email}&start_date=${startdate}&end_date=${enddate}`
    );
    return res;
  } catch (error) {
    throw error;
  }
  
}

export const downloadDailyPL = async (id = "", length = "365") => {
  try {
    const res = await instance.get(
      `api/v2/clients/earnings?email=${id}&day=${length}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const strategyGraph = async (name) => {
  try {
    const res = await instance.get(
      `api/v2/strategies/earnings?strategy_name=${name}&day=29`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const portfolliograph = async (name) => {
  try {
    const res = await instance.get(
      `api/v2/portfolios/earnings/?day=29&portfolio_name=${name}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const portfollioEarningdata= async (name) => {
  try {
    const res = await instance.get(
      `api/v2/portfolios/portfoliopl_overall?portfolio_name=${name}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const BasketEarningdata= async (name) => {
  try {
    const res = await instance.get(
      `api/v2/strategies/strategypl_overall?strategy_name=${name}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};


export const clientStrategySummary= async (data) => {
  try {
    const res = await instance1.post(
      `/api/v1/reports/analysis/client-strategy-pnl`,data
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const StrategyReportSummary= async (data) => {
  try {
    const res = await instance1.post(
      `/api/v1/reports/analysis/internal-pnl`,data
    );
    return res;
  } catch (error) {
    throw error;
  }
};