import { Icon } from "@chakra-ui/react";
import React from "react";

const CloseIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "15px"}
      height={height ? height : "16px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 16"
      _hover={hover}
      {...rest}
    >
      <path
        d="M10.714 16L7.392 10.83L4.378 16H0.902L5.742 8.256L0.836 0.644H4.378L7.7 5.792L10.692 0.644H14.168L9.35 8.366L14.256 16H10.714Z"
        // fill="white"
        // fill="white"
      />
    </Icon>
  );
};

export default CloseIcon;
