import { Image, Stack, Text } from "@chakra-ui/react";

const StrategyCard = (props) => {
  const { image, title, description, onClick } = props;
  return (
    <Stack
      p={4}
      border="1.5px solid #EBEBEB"
      borderRadius={"4px"}
      alignItems="center"
      cursor="pointer"
      onClick={onClick}
    >
      <Image src={image} />
      <Text fontWeight="700" color="#141414">
        {title}
      </Text>
      <Text
        fontSize={14}
        color="#454545"
        lineHeight={"24px"}
        textAlign="center"
        fontWeight="700"
      >
        {description}
      </Text>
    </Stack>
  );
};

export default StrategyCard;
