import { Icon } from '@chakra-ui/react'
import React from 'react'
import theme from "utils/theme"

const OrderStatusIcon = () => {
    const brand=theme.colors.brand[400]
  return (
    <Icon
            
              width="13px"
              height="12px"
              viewBox="0 0 13 12"
              fill="none"
            >
              <path
                d="M12.5 1.83203H2.5L3.72016 6.83203H11.1472L12.5 1.83203Z"
                fill={brand}
              />
              <path d="M0 1H2.35323L4.70647 9H11" stroke={brand} />
              <circle cx="5.83333" cy="11.1654" r="0.833333" fill={brand} />
              <circle cx="9.16658" cy="11.1654" r="0.833333" fill={brand} />
            </Icon>
  )
}

export default OrderStatusIcon