export const execution_days = [
  {
    id: 1,
    name: "All Days",
  },
  {
    id: 2,
    name: "Monday",
  },
  {
    id: 3,
    name: "Tuesday",
  },
  {
    id: 4,
    name: "Wednesday",
  },
  {
    id: 5,
    name: "Thursday",
  },
  {
    id: 6,
    name: "Friday",
  },
];

export const instrument_type = [
  {
    id: 1,
    name: "All Instrument",
  },
  {
    id: 2,
    name: "Stocks",
  },
  {
    id: 3,
    name: "Derivatives",
  },
  {
    id: 4,
    name: "Commodity",
  },
  {
    id: 5,
    name: "Currency",
  },
];

export const fund_manager = [
  {
    id: 1,
    name: "Any",
  },
  {
    id: 2,
    name: "UTrade Algo",
  },
  {
    id: 3,
    name: "Motilal Oswal",
  },
  {
    id: 4,
    name: "Quants Algo",
  },
  {
    id: 5,
    name: "Arihant Capital",
  },
];

export const strategy_type = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 2,
    name: "Intraday",
  },
  {
    id: 3,
    name: "Delivery",
  },
  {
    id: 4,
    name: "Carry Forward",
  },
  {
    id: 5,
    name: "Swing",
  },
];

export const faq = [
  {
    id: 1,
    question: "What is ‘Algorithm Strategy’?",
    ans: "A ‘Strategy’ is a combination of various conditions that define when to buy or sell a particular Trading Symbol on predefined algorithms to trade. For every trading strategy, one needs to define assets to trade, entry/exit points, and money management rules.",
  },
  {
    id: 2,
    question: "How to Create Algorithm Trading Strategy?",
    ans: "A ‘Strategy’ is a combination of various conditions that define when to buy or sell a particular Trading Symbol on predefined algorithms to trade. For every trading strategy, one needs to define assets to trade, entry/exit points, and money management rules.",
  },
  {
    id: 3,
    question: "How to Create Algorithm Trading Strategy?",
    ans: "A ‘Strategy’ is a combination of various conditions that define when to buy or sell a particular Trading Symbol on predefined algorithms to trade. For every trading strategy, one needs to define assets to trade, entry/exit points, and money management rules.",
  },
  {
    id: 4,
    question: "How to Create Algorithm Trading Strategy?",
    ans: "A ‘Strategy’ is a combination of various conditions that define when to buy or sell a particular Trading Symbol on predefined algorithms to trade. For every trading strategy, one needs to define assets to trade, entry/exit points, and money management rules.",
  },
  {
    id: 5,
    question: "How to Create Algorithm Trading Strategy?",
    ans: "A ‘Strategy’ is a combination of various conditions that define when to buy or sell a particular Trading Symbol on predefined algorithms to trade. For every trading strategy, one needs to define assets to trade, entry/exit points, and money management rules.",
  },
  {
    id: 6,
    question: "How to Create Algorithm Trading Strategy?",
    ans: "A ‘Strategy’ is a combination of various conditions that define when to buy or sell a particular Trading Symbol on predefined algorithms to trade. For every trading strategy, one needs to define assets to trade, entry/exit points, and money management rules.",
  },
  {
    id: 7,
    question: "How to Create Algorithm Trading Strategy?",
    ans: "A ‘Strategy’ is a combination of various conditions that define when to buy or sell a particular Trading Symbol on predefined algorithms to trade. For every trading strategy, one needs to define assets to trade, entry/exit points, and money management rules.",
  },
  {
    id: 8,
    question: "How to Create Algorithm Trading Strategy?",
    ans: "A ‘Strategy’ is a combination of various conditions that define when to buy or sell a particular Trading Symbol on predefined algorithms to trade. For every trading strategy, one needs to define assets to trade, entry/exit points, and money management rules.",
  },
];

export const data = [
  {
    id: 1,
    title: "₹ 10,00,000",
    info: "Minimum Amount",
  },
  {
    id: 2,
    title: "1x",
    info: "Multiplier",
  },
  {
    id: 3,
    title: "Nifty & Bank Nifty",
    info: "Underline",
  },
  {
    id: 4,
    title: "Long Strangle/Custom",
    info: "Strategy Type",
  },
  {
    id: 5,
    title: "All Days",
    info: "Execution Days",
  },
  {
    id: 6,
    title: "20/11/ 2023",
    info: "Date Created",
  },
  {
    id: 7,
    title: "9:30 AM",
    info: "Start Time",
  },
  {
    id: 8,
    title: "3:15 PM",
    info: "End Time",
  },
  {
    id: 9,
    title: "High",
    info: "Risk Level",
  },
];

export const fav_conditions = [
  {
    id: 1,
    title: "Fall in volatility",
  },
  {
    id: 2,
    title: "Range Bound movements",
  },
  {
    id: 3,
    title: "Weekly expiry days are best suited",
  },
  {
    id: 4,
    title: "Intraday Trades",
  },
];

export const things_keep_in_mind = [
  {
    id: 1,
    info: "Ensure Appropriate margin to execute trades on a daily basis other wise order will get rejected and performance may vary .",
  },
  {
    id: 2,
    info: "If Trades get partially executed due to less margin in account, then the open trade will get closed.",
  },
  {
    id: 3,
    info: "Keep a horizon of minimum 5 months for reviewing the product performance.",
  },
];

export const rules_of_the_product = [
  {
    id: 1,
    info: "Intraday trades only no carry trades.",
  },
  {
    id: 2,
    info: "Trade open after 9:25 am and closes before or at 3:00 pm.",
  },
];

export const multipliersOptions = [
  { label: "1X", value: "1" },
  { label: "2X", value: "2" },
  { label: "3X", value: "3" },
  { label: "4X", value: "4" },
  { label: "5X", value: "5" },
];

export const initialText = `This strategy trades in Nifty and Banknifty weekly options. This is an
intraday option selling strategy which sells both Call & Put
options. This strategy is designed for DIRECTIONAL and NON- DIRECTIONAL
movements. This Multi-leg strategy consists of six legs based on three
different strategies in this portfolio. Note: - Trading in this
strategy should be avoided on an event day. This strategy trades in
Nifty and Banknifty weekly options. This is an intraday option selling
strategy which sells both Call & Put options. This strategy is designed
for DIRECTIONAL and NON- DIRECTIONAL movements. This Multi-leg
strategy consists of six legs based on three different strategies in
this portfolio. Note: - Trading in this strategy should be avoided on
an event day.`;
