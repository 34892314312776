import React from 'react'
import theme from "../../utils/theme";
import { Box } from '@chakra-ui/react';
const BasketIcon = ({ height, width ,color}) => {
  // const color=theme.colors.color[900]
  return (
    <Box display={"flex"} alignItems={"center"}>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 13" fill="none">
    <path d="M8.00967 2.76923C8.00967 4.15845 7.08326 5.16346 6.07698 5.16346C5.0707 5.16346 4.14429 4.15845 4.14429 2.76923C4.14429 1.38001 5.0707 0.375 6.07698 0.375C7.08326 0.375 8.00967 1.38001 8.00967 2.76923Z" stroke={color} stroke-width="0.75"/>
    <rect x="2.84619" y="1.84766" width="6.46154" height="1.84615" fill="black"/>
    <path d="M2.84619 0.5C2.84619 0.223858 3.07005 0 3.34619 0H8.80773C9.08387 0 9.30773 0.223858 9.30773 0.5V1.84615H2.84619V0.5Z" fill="black"/>
    <path d="M10.4769 1.84766H1.77363L1 12.0015H11.1538L10.4769 1.84766Z" stroke={color} stroke-linecap="round"/>
    <rect x="1" y="9" width="10" height="3" fill={color}/>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 4 6" fill="none">
  <path d="M1 1L3 3L1 5" stroke={color}/>
</svg>
    </Box>
  )
}

export default BasketIcon