import {
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import CloseIcon from "assets/icons/close";
import StrategyCard from "components/elements/StrategyCard";
import UploadCSV from "assets/svgs/create-strategy-1.svg";
import Create from "assets/svgs/create-strategy-2.svg";
import { useNavigate } from "react-router-dom";


const CreateStrategy = (props) => {
  const { isOpen, onClose, onOpen } = props;

  const navigate = useNavigate();
  const data = [
    {
      image: UploadCSV,
      title: "Download & Upload Spreadsheet",
      description:
        "Click on card to download the sheet along with respected heading and fill them accordingly & upload",
      onClick: () => {
        onOpen();
        onClose();
      },
    },
    {
      image: Create,
      title: " Strategy inside Application",
      description:
        "Click on card to create strategy inside application and fill the information, select symbols & hit save button ",
      onClick: () => {
        navigate("/create-strategy");
        onClose();
      },
    },
  ];
  let primary_color = localStorage.getItem("primary_color") ||  process.env.REACT_APP_PRIMARY_COLOR;
  let secondary_color = localStorage.getItem("secondary_color") || process.env.REACT_APP_SECONDARY_COLOR;
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={"2xl"}>
      <ModalOverlay />
      <ModalContent overflow={"hidden"} borderTopRightRadius={"18"} 
          borderTopLeftRadius={"18"}  borderBottomLeftRadius={"18"}
          borderBottomRightRadius={"18"}  bg="white" >
        <ModalHeader
          borderTopRightRadius={"18"}
          borderTopLeftRadius={"18"}
          fontSize={22}
          fontWeight="700"
          fontFamily="Poppins"
          // bg={process.env.REACT_APP_PRIMARY_COLOR}
          bg={"brand.900"}
          color={"brand.500"}
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
          p={4}
        >
          Create Strategy
          <ModalCloseButton position={"relative"} top={0} right={0}>
            <CloseIcon />
          </ModalCloseButton>
        </ModalHeader>
        <ModalBody p={4}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            {data.map((item, i) => {
              return (
                <GridItem w="100%" key={i}>
                  <StrategyCard
                    image={item?.image}
                    title={item.title}
                    description={item.description}
                    onClick={item.onClick}
                  />
                </GridItem>
              );
            })}
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateStrategy;
