import { Icon } from '@chakra-ui/react'
import React from 'react'
import theme from "utils/theme"
const BrokerRecordStatusIcon = () => {
    const brand=theme.colors.brand[400]
  return (
    <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="11px"
    height="10px"
    viewBox="0 0 11 10"
    fill="none"
  >
    <rect
      y="1.57031"
      width="11"
      height="7.85714"
      rx="2"
      fill={brand}
    />
    <path
      d="M0 1C0 0.447715 0.447715 0 1 0H2.42466C4.08151 0 5.42466 1.34314 5.42466 3V3.61644H0V1Z"
      fill={brand}
    />
  </Icon>
  )
}

export default BrokerRecordStatusIcon