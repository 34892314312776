// theme.js
import { extendTheme } from "@chakra-ui/react";
import { checkboxTheme } from "./Checkbox";
import { radioTheme } from "./Radio";
import { accordionTheme } from "./Accordion";
import "@fontsource/lato"; // Defaults to weight 400
import "@fontsource/lato/400.css"; // Specify weight
import "@fontsource/lato/400-italic.css"; // Specify weight and style

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};
let primary_color =
  localStorage.getItem("primary_color") || process.env.REACT_APP_PRIMARY_COLOR;
let secondary_color =
  localStorage.getItem("secondary_color") ||
  process.env.REACT_APP_SECONDARY_COLOR;

const theme = extendTheme({
  colors: {
    brand: {
      // 900: "rgba(0, 0, 255, 0.60)",//
      // 100:"F3F7FE",
      // 200:"#E8EBFE",
      // 300:"linear-gradient(to right, #B0A3FF, #7262FF, #7263FF,#B0B3FF)",
      // 600:"rgba(0, 0, 255, 0.60)",
      // 500: "white",

      700: "white", // moti

      400: "#333333", // moti
    

      // 300: "linear-gradient(90deg, rgba(241,232,224,1) 0%, rgba(228,154,32,1) 30%, rgba(230,221,205,1) 91%)",
      300:"white",
      // 900: "#FECE72", // moti
      900:"#F8AF0F",
      100: "#FEF8E6",
      200: "rgba(252, 175, 23, 0.15)",
      600: "#333333",
      500: "#333333", //moti fo
    },
    // white: {
    //   100: "#FFFFFF",
    // },
    whitee:{
      100: "#FFFFFF",
    },
    gray: {
      // 100: "#E2E2E2",
      200: "#CCCCCC",
      // 300: "#666666",
      400: "#7D7D8D",
      500: "#4141411F",
      // 600: "#00000029",
      700: "#DDDDDD",
      // 800: "#F6F6F6",
    },
  
    black: {
      500: "#000000",
    },
  },
  fonts: {
    heading: `Lato`,
    body: `Lato`,
  },
  
  components: {
    Checkbox: checkboxTheme,
    Radio: radioTheme,
    Accordion: accordionTheme,
    Avatar: {
      sizes: {
        md: {
          container: {
            height: "36px",
            width: "36px",
            background: primary_color || process.env.REACT_APP_PRIMARY_COLOR,
            color: "#141414",
          },
          label: {
            fontSize: 16,
          },
        },
      },
      variants: {
        outline: {
          container: {
            background:
              secondary_color || process.env.REACT_APP_SECONDARY_COLOR,
            color: primary_color || process.env.REACT_APP_PRIMARY_COLOR,
            border: `1px solid  ${
              primary_color || process.env.REACT_APP_PRIMARY_COLOR
            }`,
          },
        },
      },
    },
    Button: {
      variants: {
        outline: {
          border: `1px solid  ${secondary_color} || ${process.env.REACT_APP_SECONDARY_COLOR}`,
          background: secondary_color || process.env.REACT_APP_SECONDARY_COLOR,
          color: primary_color || process.env.REACT_APP_PRIMARY_COLOR,
        },
      },
    },

    Tabs: {
      variants: {
        custom: {
          tablist: {
            background: "white",
            padding: 1,
            borderRadius: "6px",
            fontWeight: 700,
          },
          tab: {
            color: "black",
            fontWeight: "700",
            _selected: {
              background: "brand.900",
              borderRadius: "8px",
              color: "white",
              fontWeight: 700,
            },
          },
        },
      },
    },
  },
  // styles: {
  //   global: {
  //     "::-webkit-scrollbar": {
  //       width: "8px", // Reduce width
  //       height: "8px", // Reduce height
  //     },
  //     "::-webkit-scrollbar-thumb": {
  //       backgroundColor: "brand.900",
  //       borderRadius: "4px",
  //     },
  //     "::-webkit-scrollbar-track": {
  //       backgroundColor: "gray.200",
  //     },
  //   },
  // },
  ...config,
});

export default theme;
