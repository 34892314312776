import instance, { request } from "utils/interceptor";
export const UploadCSV = async (data, method = "post", headers) => {
  try {
    const res = await instance[method](
      "/api/v2/client-strategies/upload/csv",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    throw error;
  }
};
