import { Button} from "@chakra-ui/react";
import React from "react";
import {colors} from "styles/colors";
import env from "react-dotenv";

const FormButton = (props) => {
  const {
    text,
    variant,
    icon,
    rightIcon,
    leftIcon,
    fontSize,
    fontWeight,
    hover,color,
    ...rest
  } = props;
  


  
  return (
    <Button
      textTransform={"capitalize"}
      fontSize={fontSize ? fontSize : 16}
     
      // background: linear-gradient(90deg, rgba(255,210,0,1) 34%, rgba(247,151,30,1) 77%);
      backgroundColor={"brand.900" } 
      
      // _hover={{background:"linear-gradient(90deg, rgba(255,210,0,1) 34%, rgba(247,151,30,1) 90%)"}}
      borderColor="transparent"
      fontWeight="700"
      variant={variant || "solid"}
      rightIcon={rightIcon}
      leftIcon={leftIcon}
      borderRadius={3}
     
      {...rest}
      textColor={ color || "brand.500"}
    >
      {icon && <span>{icon}</span>}
      {text}
    </Button>
  );
};

export default FormButton;
