import { Box } from '@chakra-ui/react'
import React from 'react'
import theme from "../../utils/theme";
const LogoutIcon = ({ height, width }) => {
  const color=theme.colors.brand[600]
  return (
    <Box>

    
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 29 28" fill="none">
    <g filter="url(#filter0_d_352_656)">
      <rect x="4" y="0.335938" width="20.2759" height="19.6615" rx="9.83073" fill={color}/>
    </g>
    <circle cx="14" cy="9.99609" r="4" stroke="white" stroke-width="2"/>
    <rect x="11.5" y="1.66406" width="5" height="6.66667" fill={color}/>
    <path d="M14 4.99609L14 8.32943" stroke="white" stroke-width="2" stroke-linecap="round"/>
    <defs>
      <filter id="filter0_d_352_656" x="0" y="0.335938" width="28.2759" height="27.6602" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_352_656"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_352_656" result="shape"/>
      </filter>
    </defs>
  </svg>
  </Box>
  )
}

export default LogoutIcon