// MyIcon.js

import { Box, Icon } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../utils/theme";
const ProfileIcon = ({ height, width,navigatee }) => {
  const navigate = useNavigate();
  const color=theme.colors.brand[600]
  return (
    <Box cursor={"pointer"}  onClick={()=> navigate(navigatee)}> 

   
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 29 28" fill="none">
  <g filter="url(#filter0_d_352_647)">
    <rect x="4" width="20.2759" height="19.6615" rx="9.83073" fill={color}/>
  </g>
  <rect x="11.0276" y="10.8945" width="6.66631" height="5.32436" rx="2.66218" fill={color} stroke="white" stroke-width="2"/>
  <path d="M16.3532 7.3034C16.3532 8.49069 15.3982 9.44273 14.233 9.44273C13.0678 9.44273 12.1128 8.49069 12.1128 7.3034C12.1128 6.1161 13.0678 5.16406 14.233 5.16406C15.3982 5.16406 16.3532 6.1161 16.3532 7.3034Z" fill="white" stroke="white" stroke-width="2"/>
  <rect x="9.65747" y="14.4922" width="9.36614" height="3.044" fill={color}/>
  <path d="M11.0422 12.9375C11.0422 11.8329 11.9377 10.9375 13.0422 10.9375H15.026C16.6829 10.9375 18.026 12.2806 18.026 13.9375V14.4508H11.0422V12.9375Z" fill="white"/>
  <defs>
    <filter id="filter0_d_352_647" x="0" y="0" width="28.2759" height="27.6602" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_352_647"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_352_647" result="shape"/>
    </filter>
  </defs>
</svg>
</Box>
  );
};

export default ProfileIcon;
