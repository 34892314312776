import { Icon } from '@chakra-ui/react'
import React from 'react'

const MarketPlace = () => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="28.3" height="28.3" viewBox="0 0 24.3 24.3">
    <g  transform="translate(-0.349 0.151)">
      <g >
        <rect width="28" height="28" transform="translate(0.499 -0.001)" stroke="#000" stroke-width="0.3" opacity="0"/>
        <g  transform="translate(0.676 1.758)">
          <path d="M14.8,22.667A1.881,1.881,0,0,0,12.667,24.8v2.131h4.261V24.8A1.881,1.881,0,0,0,14.8,22.667Z" transform="translate(-3.453 -9.112)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
          <path  d="M4.013,16.96v1.931c0,3.827,1.534,5.361,5.361,5.361h4.594c3.827,0,5.361-1.534,5.361-5.361V16.96" transform="translate(-0.331 -6.436)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
          <path  d="M14.55,11.189A2.527,2.527,0,0,0,17.106,8.36l-.562-5.693h-3.98L11.993,8.36A2.527,2.527,0,0,0,14.55,11.189Z" transform="translate(-3.205)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
          <path  d="M22.484,11.189A2.775,2.775,0,0,0,25.3,8.078l-.239-2.344c-.307-2.216-1.159-3.068-3.392-3.068h-2.6l.6,5.974A2.9,2.9,0,0,0,22.484,11.189Z" transform="translate(-5.762)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
          <path  d="M5.924,11.189A2.889,2.889,0,0,0,8.737,8.641l.187-1.883.409-4.091h-2.6c-2.233,0-3.085.852-3.392,3.068l-.23,2.344A2.775,2.775,0,0,0,5.924,11.189Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
        </g>
      </g>
    </g>
  </Icon>
  
  )
}

export default MarketPlace