import React from 'react'
import theme from "../../utils/theme";
const KillSwitchIcon = ({ height, width }) => {
  const color=theme.colors.brand[600]
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 29" fill="none">
  <g filter="url(#filter0_d_352_618)">
    <rect x="4.69092" width="20.6402" height="20.0148" rx="10.0074" fill={color}/>
  </g>
  <rect x="9.66284" y="6.66406" width="11.9942" height="6.63068" rx="3.31534" fill="white"/>
  <ellipse cx="13.7899" cy="9.97709" rx="2.28595" ry="2.25443" fill={color}/>
  <defs>
    <filter id="filter0_d_352_618" x="0.690918" y="0" width="28.6401" height="28.0156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_352_618"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_352_618" result="shape"/>
    </filter>
  </defs>
</svg>
  )
}

export default KillSwitchIcon