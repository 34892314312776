import Logo from "assets/images/Kara_Sec_Logo-removebg-preview.png";

export const colors = {
  primary: "#FFD24C",
  secondary:"#FFE69A" ,
  API_URL:"https://demo.arjunconsulting.in",
  Logo:"assets/images/Kara_Sec_Logo-removebg-preview.png",
  Logo:{Logo}
  // success: "#05A56B",
  // danger: "#d7535a",
  // blue: "#4c69ec",
  // gray5: "#454545",
};


export default colors;
