
import {
  Box,
  Flex,
  Grid,
  HStack,
  Image,
  Portal,
  Text,
  Toast,
  Tooltip,
  Avatar,
  Button,
  Card,
  GridItem,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import { CloseIcon } from "@chakra-ui/icons";
import BelllIcon from "assets/icons/BellIcon";
import KillSwitchIcon from "assets/icons/KillSwitchIcon";
import LogoutIcon from "assets/icons/LogoutIcon";
import ProfileIcon from "assets/icons/ProfileIcon";
import Logo from "assets/icons/logo.svg";
import { notification, seenNotification } from "apis/subscription";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { KillSwitch } from "apis/client";
import { BiBell } from "react-icons/bi";
import urls from "utils/routes";
import theme from "utils/theme";
import { logout } from "apis/auth";

const Navbar = () => {
  const [subscription, setSubscriptions] = useState([]);
  const navigate = useNavigate();
  const [showPopover, setShowPopover] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  let admin = localStorage.getItem("admin");

  const logoutt = async () => {
    const res = await logout();
    localStorage.clear();
    navigate("/");
  };

  const handleKillSwitch = () => {
    let token = localStorage.getItem("accessToken");
    let headers = {
      "Content-type": "application/json",
      AUTHENTICATION: token,
    };

    KillSwitch("/api/v1/clients/kill-switch", headers);
  };

  return (
    <>
      <Box bg="#F3F7FE" w="100%">
        <Box display={"flex"} w="100%" height="2.6875rem">
          <Box
            width="7%"
            bg="white"
            height="2.9875rem"
            color="#00F"
            fontFamily="body"
            fontSize="1.5375rem"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="normal"
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            ml="1"
            onClick={() => {
              navigate("/dashboard");
              window.location.reload();
            }}
            cursor={"pointer"}
          >
            <Image w="100%" h="100%" src={Logo} />
          </Box>
          <Box
            float="right"
            w="93%"
            height="2.6875rem"
            flexShrink="0"
            bg="brand.300"
            display="flex"
            alignItems="center"
            justifyContent={"end"}
          >
            {/* <Tooltip
              label="Profile"
              hasArrow
              placement="bottom"
              bg="brand.900"
              color="brand.400"
            >
              <span>
                <ProfileIcon
                  navigatee={"/profile/edit"}
                  height={"32px"}
                  width="32px"
                />
              </span>
            </Tooltip> */}

            <Tooltip
              label="Logout"
              hasArrow
              placement="bottom"
              bg="brand.900"
              color="brand.400"
            >
              <span>
                <span style={{ cursor: "pointer" }} onClick={onOpen}>
                  <LogoutIcon height={"32px"} width="32px" />
                </span>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <Flex justifyContent="space-between" alignItems="center">
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Logout
              </AlertDialogHeader>
              <IconButton
                icon={<CloseIcon />}
                aria-label="Close"
                onClick={onClose}
                variant="ghost"
              />
            </Flex>

            <AlertDialogBody>Are you sure you want to log out?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                No
              </Button>
              <Button bg="brand.900" onClick={logoutt} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Navbar;
