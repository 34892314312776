import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Text,
  Td,
  HStack
} from "@chakra-ui/react";

const headings = [
  "Symbol",
  "product Type",
  "Completed Order",
  "Rejected Order",
  "Unrealized P&L",
  "Realized P&L",
];
const ClientPandL = (props) => {
  const { setIsCompletedOrderModal, isOpen, onClose, modaldata } = props;

 

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"6xl"}>
      <ModalOverlay />
      <ModalContent height="auto" borderTopRadius={"18"}  borderBottomLeftRadius={"18"}  bg="white"
          borderBottomRightRadius={"18"}>
        <ModalHeader
           borderTopRightRadius={"18"}
           borderTopLeftRadius={"18"}
           
        
          color="brand.700"
             fontWeight="700"
             bg={"brand.900"}
        >
          <Text>P&L </Text>{" "}
        </ModalHeader>
        <ModalCloseButton onClick={() => setIsCompletedOrderModal(false)} />
        <ModalBody overflow={"auto"}>
          <TableContainer
            height="390px"
            maxHeight="410px"
            my={4}
            border="1px solid #eef3f4"
            overflowY="auto"
          >
            <Table variant="custom">
              <Thead >
                <Tr  bg={"brand.900"} >
                  {headings.map((item, index) => {
                    return <Th color={"brand.500"} fontWeight="700"   borderRight="2px solid white"
                    borderTopLeftRadius={index===0 &&  "15px"} key={index}>{item}</Th>;
                  })}
                </Tr>
              </Thead>

              <Tbody bg="white">
                {modaldata?.length > 0 ? (
                  modaldata?.map((item, index) => {
                    return (
                      <Tr
                        key={index}
                        height="50px"
                        textAlign="start"
                        verticalAlign="center"
                        display=""
                        fontSize="15px"
                        fontWeight="700"
                      >
                        <Td>
                          {item?.symbol +
                            "|" +
                            item?.expiry +
                            "|" +
                            item?.strike +
                            "|" +
                            item?.right}
                        </Td>
                        <Td>{item?.product_type}</Td>
                        <Td py="6px">{item?.today_completed_orders}</Td>
                        <Td py="6px">{item?.today_error_or_rejected_orders}</Td>
                        <Td
                          py="6px"
                          // color={
                          //   item.today_unrealized_totalpl < 0 ? "#E90303" : "#008000"
                          // }
                          color={
                            item?.today_unrealized_totalpl < 0
                              ? "red"
                              : item?.today_unrealized_totalpl > 0
                              ? "green"
                              : "black"
                          }
                        >
                          {item?.today_unrealized_totalpl.toLocaleString(
                            "en-IN",
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }
                          )}
                        </Td>
                        <Td
                          py="6px"
                          color={
                            item?.realized_totalpl < 0
                              ? "red"
                              : item?.realized_totalpl > 0
                              ? "green"
                              : "black"
                          }
                        >
                          {item?.realized_totalpl.toLocaleString(
                            "en-IN",
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }
                          )}
                        </Td>
                      </Tr>
                    );
                  })
                ) : (
                  <HStack justify="center" alignItems="center" p={2}>
                    <Text textAlign="center">No data Available</Text>
                  </HStack>
                )}
              </Tbody>
             
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter  borderBottomLeftRadius={"18"}
          borderBottomRightRadius={"18"}>
            
          <Button
            variant="ghost"
            bg={"brand.900"}
            color={process.env.REACT_APP_MODAL_TITLE_COLOR}
            onClick={() => setIsCompletedOrderModal(false)}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ClientPandL;
