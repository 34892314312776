import { useState } from "react";
import JSZip from "jszip";
import { Button } from "@chakra-ui/react";
import { downloadDatagraphtranc2, downloadOrderDetails } from "apis/dashboard";
import {
  customEarning,
  customtransaction,
  getDailyEarning,
} from "apis/viewgrph";
import { getIndivisualClientsListPL } from "apis/client";
import moment from "moment";
var XLSX = require("xlsx");

const DownloadClientData = ({
  clientEmails,
  pageData,
  selected,
  formatdate,
}) => {
  const [isDownloadLoading, setIsDowndloading] = useState(false);
  let primary_color =
    localStorage.getItem("primary_color") ||
    process.env.REACT_APP_PRIMARY_COLOR;
  let secondary_color =
    localStorage.getItem("secondary_color") ||
    process.env.REACT_APP_SECONDARY_COLOR;
  //   generate the excel file

  function generateExcelSheetContent(email) {
    return new Promise((resolve, reject) => {
      Promise.all([
        selected.label === "Custom"
          ? customtransaction(email, formatdate.startdate, formatdate.enddate)
          : selected.label === "Monthly"
          // ? downloadOrderDetails({ email: email }, 29)
          ? downloadDatagraphtranc2(email,29)
          : selected.label === "Yearly"
          // ? downloadOrderDetails({ email: email }, 365)
          ? downloadDatagraphtranc2(email,365)
          : selected.label === "Weekly"
          // ? downloadOrderDetails({ email: email }, 7)
          ? downloadDatagraphtranc2(email,7)
          : downloadOrderDetails({ email: email }, pageData.total),

        selected.label === "Custom"
          ? customEarning(email, formatdate.startdate, formatdate.enddate)
          : selected.label === "Monthly"
          ? getDailyEarning(email, 29)
          : selected.label === "Yearly"
          ? getDailyEarning(email, 365)
          : selected.label === "Weekly"
          ? getDailyEarning(email, 7)
          : getDailyEarning(email, 7),

        getIndivisualClientsListPL(email),
      ])
        .then((res) => {
          const transationData = selected.label === "Custom" ?  res[0]?.data[0]?.items :res[0]?.data[0];

          let overallPL = 0;
          let net_pnl=0;
          let dailyPlData = []; // Initialize dailyPlData as an empty array

          if (res[1]?.data[0]) {
            const response = res[1].data[0].sort(
              (a, b) => new Date(b.date) - new Date(a.date)
            );

            const formattedData = response
              ?.slice()
              .reverse()
              .map((item) => {
                overallPL += item?.pl;
                net_pnl+=item?.net_pnl
                const formattedDate = new Date(item.date)
                  .toISOString()
                  .split("T")[0];
                return {
                  Date: formattedDate,
                  "P&L": item.pl,
                  OverallPL: overallPL,
                  "NetPL":net_pnl,
                };
              });
            dailyPlData = formattedData.reverse();
          }

          const statdata = res[2]?.data[0].items;

          

          const sortedData = transationData.sort((a, b) => new Date(b.order_place_time) - new Date(a.order_place_time));
          const sheetData = sortedData.map((item) => {
            const combinedDateTime = moment(item?.order_place_time).format(
              "D MMM YYYY, h:mm:s A"
            );
          
            const [datePart, timePart] = combinedDateTime.split(", ");

            return {
              STRATEGY: item.strategy_name,
              QUANTITY: item.quantity,
              PRICE: item.order_price,
              SYMBOL: item.symbol,
              EXPIRY: item.expiry,
              STRIKE: item.strike,
              RIGHT: item.right,
              STATUS: item.order_status,
              ACTION: item.transaction_type,
              DATE: datePart,
              TIME: timePart,
            };
          });

          const stats = statdata?.map((item) => {
            const completedOrdersCount = transationData.reduce(
              (count, transItem) => {
                if (transItem.order_status === "Completed") {
                  return count + 1;
                }
                return count;
              },
              0
            ); // Initialize count to 0

            return {
              "Email Id": item?.client_keev_id,
              "Completed orders": completedOrdersCount,
              "OverallP&L": dailyPlData[0]?.OverallPL || 0,
              "NetPL":dailyPlData[0]?.NetPL || 0,
            };
          });

          // create a new workbook
          const workbook = XLSX.utils.book_new();

          // Sheet one
          const worksheet2 = XLSX.utils.json_to_sheet(stats);
          XLSX.utils.book_append_sheet(workbook, worksheet2, "Overview");

          // sheet two
          const worksheet = XLSX.utils.json_to_sheet(sheetData);
          XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

          // sheet three
          const worksheet3 = XLSX.utils.json_to_sheet(dailyPlData);
          XLSX.utils.book_append_sheet(workbook, worksheet3, "Daily PNL");

          // write a workbook
          const content = XLSX.write(workbook, {
            type: "array",
            bookType: "xlsx",
          });
          const blob = new Blob([content], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          resolve(blob);
        })
        .catch((error) => {
          reject(error);
          setIsDowndloading(false);
        });
    });
  }
  function downloadZipFile() {
    const zip = new JSZip();
    const folder = zip.folder("Clients Data");

    // Add each Excel file to the folder
    // Here, I'm assuming the sheet names are 'sheet1', 'sheet2', etc.
    for (let i = 0; i <= clientEmails.length - 1; i++) {
      const sheetName = clientEmails[i];
      const sheetContent = generateExcelSheetContent(clientEmails[i]); // a function to generate excel sheet content
      folder.file(`${sheetName}.xlsx`, sheetContent);
    }

    // Generate the ZIP file
    zip.generateAsync({ type: "blob" }).then((content) => {
      // Trigger the download
      const url = URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.download = "excel-files.zip";
      link.click();
      setIsDowndloading(false);
    });
  }
  return (
    <Button
      bg={"brand.900"}
      isLoading={isDownloadLoading}
      loadingText={isDownloadLoading ? "Downloading..." : "Download"}
      color="brand.400"
      fontWeight="700"
      onClick={() => {
        downloadZipFile();
        setIsDowndloading(true);
      }}
      _hover={{ bg: primary_color }}
     
      isDisabled={clientEmails.length > 0 ? false : true}
      mr={clientEmails.length > 0 ? "30px" : 0}
    >
      Download
    </Button>
  );
};

export default DownloadClientData;
