import React from 'react'
import theme from "../../utils/theme";
import { Icon } from '@chakra-ui/react';
const Trade = ({ height, width ,color}) => {
 
  return (

    <Icon xmlns="http://www.w3.org/2000/svg"  width={width} height={height} viewBox="0 0 14 12"  fill="none">
    <path d="M3.31415 1L0.674072 4.99606M3.31415 1H7.04468M3.31415 1L4.57679 4.92866M13.5301 4.77406L10.7752 1H7.04468M13.5301 4.77406L7.21686 11.1011M13.5301 4.77406L9.62736 4.84145M7.21686 11.1011L0.674072 4.99606M7.21686 11.1011L4.57679 4.92866M7.21686 11.1011L9.62736 4.92866M0.674072 4.99606L4.57679 4.92866M10.7752 1.111L9.62736 4.84145M9.62736 4.84145L7.04468 1M9.62736 4.84145L4.57679 4.92866M7.04468 1L4.57679 4.92866" stroke={color} stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.10203 1L9.4878 5.13229H4.71625L7.10203 1Z" fill={color}/>
    <path d="M11.1087 1.46745L12.8731 4.44066L9.7484 4.64589L11.1087 1.46745Z" fill={color}/>
    <path d="M3.05584 1.64721L4.99418 4.61758L1.28641 4.72119L3.05584 1.64721Z" fill={color}/>
  </Icon>
  )
}

export default Trade