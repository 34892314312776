import React from 'react'
import theme from "../../utils/theme";
import { Box } from '@chakra-ui/react';
const UserIcon = ({ height, width,color }) => {
  // const color=theme.colors.color[900]
  return (
    <Box display="flex" alignItems={"center"}>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 11 16" fill="none">
  <rect x="1" y="7.89062" width="9" height="6.81483" rx="2" fill="#A3AED0" stroke="#A3AED0" stroke-width="2"/>
  <path d="M8.29813 3.77817C8.29813 5.26865 7.01763 6.55635 5.33773 6.55635C3.65782 6.55635 2.37732 5.26865 2.37732 3.77817C2.37732 2.2877 3.65782 1 5.33773 1C7.01763 1 8.29813 2.2877 8.29813 3.77817Z" fill="#A3AED0" stroke="#A3AED0" stroke-width="2"/>
  <path d="M1 8.89063C1 8.33834 1.44772 7.89062 2 7.89062H9C9.55228 7.89062 10 8.33834 10 8.89062V13.7055C10 14.2577 9.55228 14.7055 9 14.7055H2C1.44772 14.7055 1 14.2577 1 13.7055V8.89063Z" fill={color} stroke={color} stroke-width="2"/>
  <path d="M8.29813 3.77817C8.29813 5.26865 7.01763 6.55635 5.33773 6.55635C3.65782 6.55635 2.37732 5.26865 2.37732 3.77817C2.37732 2.2877 3.65782 1 5.33773 1C7.01763 1 8.29813 2.2877 8.29813 3.77817Z" fill={color} stroke={color} stroke-width="2"/>
  <rect y="12" width="11" height="4" fill="white"/>
  <path d="M1 9C1 8.44772 1.44772 8 2 8H9C9.55229 8 10 8.44772 10 9V13H1V9Z" fill="white"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 4 6" fill="none">
  <path d="M1 1L3 3L1 5" stroke={color}/>
</svg>
    </Box>
  )
}

export default UserIcon