import instance from "utils/interceptor";
import instance1 from "utils/interceptor1";
export const login = async (data) => {
  try {
    const res = await instance1.post("/api/v1/auth/login", data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const logout = async () => {
  try {
    const res = await instance1.get("/api/v1/auth/logout");
    return res;
  } catch (error) {
    throw error;
  }
};
export const RefreshToken = async (data) => {
  try {
    const res = await instance1.post("/api/v1/auth/refresh-token", data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const setBrokrage = async (data) => {
  try {
    const res = await instance.put("/api/v2/users/brokerage-update", data);
    return res;
  } catch (error) {
    throw error;
  }
};
export const CreateUser = async (data) => {
  try {
    const res = await instance.post("/api/v2/users", data);
    return res;
  } catch (error) {
    throw error;
  }
};
export const AddmaxQty = async (data) => {
  try {
    const res = await instance.put("/api/v2/users/update-maxqty", data);
    return res;
  } catch (error) {
    throw error;
  }
};
export const GetmaxQty = async (data) => {
  try {
    const res = await instance.get("/api/v2/users/max-qty-list", data);
    return res;
  } catch (error) {
    throw error;
  }
};
export const GetBrokerage = async (id) => {
  try {
    const res = await instance.get(`/api/v2/users/brokerage?id=${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};
