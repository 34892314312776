// import async from "react-select/dist/declarations/src/async";
import instance from "utils/interceptor";
import instance1 from "utils/interceptor1";

export const detailsCard = async () => {
  try {
    const res = await instance1.get("/api/v1/reports/order/dashbaord-stats");
    return res;
  } catch (error) {
    throw error;
  }
};

export const openPositionCount = async () => {
  try {
    const res = await instance.get("/api/v2/orders/count_open");
    return res;
  } catch (error) {
    throw error;
  }
};

export const orderDetails = async (data, size, page) => {
  try {
    if (data !== null) {
      let a = false;
      let url = ``;
      if (data.email !== undefined) {
        url = url + `email=${data.email}`;
        a = true;
      }
      if (data.strategy_name !== undefined) {
        if (a === true) {
          url = url + `&`;
        }
        url = url + `strategy_name=${encodeURIComponent(data.strategy_name)}`;
        a = true;
      }
      if (data.status !== undefined) {
        if (a === true) {
          url = url + `&`;
        }
        url = url + `status=${data.status}`;
        a = true;
      }

      if (data.startdate !== undefined && data.enddate !== undefined) {
        if (a === true) {
          url = url + `&`;
        }
        url = url + `start_date=${data.startdate}&end_date=${data.enddate}`;
      }

      const res = await instance.get(
        `/api/v2/orders/dashboard/?size=${size}&page=${page}&${url}`
      );
      return res;
    } else {
      const res = await instance.get(
        `/api/v2/orders?page=${page}&size=${size}`
      );
      return res;
    }
  } catch (error) {
    throw error;
  }
};

export const dasdata = async (data)=>{
  try {
    const res = await instance1.post(
      `api/v1/reports/order` ,data
    );
    return res;
  }catch (error) {
throw error
  }
}
// export const orderDetails = async (data,size, page) => {

//   try {
//     let a = false;
//     let url = ``;
//     if (data.email !== undefined) {
//       url = url + `email=${data.email}`;
//       a = true;
//     }
//     if (data.strategy_name !== undefined) {
//       if (a === true) {
//         url = url + `&`;
//       }
//       url = url + `strategy_name=${encodeURIComponent(data.strategy_name)}`;
//       a = true;
//     }
//     if (data.status !== undefined) {
//       if (a === true) {
//         url = url + `&`;
//       }
//       url = url + `status=${data.status}`;
//       a = true;
//     }

//     if (data.startdate !== undefined && data.enddate !== undefined) {
//       if (a === true) {
//         url = url + `&`;
//       }
//       url = url + `start_date=${data.startdate}&end_date=${data.enddate}`;
//     }

//     const res = await instance.get(
//       `/api/v2/orders/dashboard/?size=${size}&page=${page}&${url}`
//     );
//     return res;
//   } catch (error) {
//     throw error;
//   }
// };

export const downloadDatagraphtranc = async (data, status,date) => {

  try {
    let url
    if(Object.keys(date).length === 0){

      url = `/api/v2/orders/daywise-orders?days=365&keev_email=${data.email}`;
    }else{
      url = `/api/v2/orders/daywise-orders?keev_email=${data.email}&start_date=${date.startdate}&end_date=${date.enddate}`;
    }

    if (status) {
      // Append status to the URL only if status is truthy
      url += `&status=${status}`;
    }

    const res = await instance.get(url);
    return res;
  } catch (error) {
    throw error;
  }
};
export const dashBoardData = async (data,page,size,id) => {

  try {
 if(id===1){

   const res = await instance1.post(`/api/v1/reports/order/fields/?page_size=${size}&page_number=${page}`,data);
   return res;
 }else{
  const res = await instance1.post(`/api/v1/reports/order/historical?page_size=${size}&page_number=${page}`,data);
  return res;
 }

  } catch (error) {
    throw error;
  }
};
export const dashBoardData1 = async (page,size,id) => {

  try {
    if(id===1){
      
      const res = await instance1.get(`/api/v1/reports/order?page_size=${size}&page_number=${page}`);
       return res;
    }else{
      const res = await instance1.post(`/api/v1/reports/order/historical?page_size=${size}&page_number=${page}`,{});
      return res;
    }
  } catch (error) {
    throw error;
  }
};
export const downloadDatagraphtranc2 = async (email, day) => {
  try {
    const res = await instance.get(
      `/api/v2/orders/daywise-orders?days=${day}&keev_email=${email}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};


export const downloadOrderDetails = async (data,id) => {
 
  try {
   
if(id===1){
  const res = await instance1.post(`/api/v1/reports/order/all`,data);
  return res;
}else{
  const res = await instance1.post(`/api/v1/reports/order/historical-all`,data);
  return res;
}
    
  } catch (error) {
    throw error;
  }
};

// export const downloadOrderDetails = async (data, size = 365) => {
//   try {
//     let a = false;
//     let url = ``;
//     if (data.email !== undefined) {
//       url = url + "&";
//       url = url + `keev_email=${data.email}`;
//       a = true;
//     }
//     if (data.strategy_name !== undefined) {
//       if (a === true) {
//         url = url + `&`;
//       }
//       url = url + `strategy_name=${data.strategy_name}`;
//       a = true;
//     }
//     if (data.status !== undefined) {
//       if (a === true) {
//         url = url + `&`;
//       }
//       url = url + `status=${data.status}`;
//       a = true;
//     }

//     if (data.startdate !== undefined && data.enddate !== undefined) {
//       if (a === true) {
//         url = url + `&`;
//       }
//       url = url + `start_date=${data.startdate}&end_date=${data.enddate}`;
//     }

//     const res = await instance.get(`/api/v2/orders/download-data?${url}`);
//     return res;
//   } catch (error) {
//     throw error;
//   }
// };

export const downloadTransationDetails = async (data) => {
  try {
    const res = await instance.get(`api/v2/orders/download`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const clientsList = async () => {
  try {
    let res = await instance1.get("/api/v1/clients?page=1&size=500");
    return res;
  } catch (error) {
    throw error;
  }
};

export const filterListsOpenPosition = async () => {
  try {
    let res = await instance.get("/api/v2/orders/open_position_filter_list");
    return res;
  } catch (error) {
    throw error;
  }
};

export const filterListsbrokerclient = async () => {
  try {
    let res = await instance.get("/api/v2/orders/broker_qty_filter_list");
    return res;
  } catch (error) {
    throw error;
  }
};

export const symbolsList = async () => {
  try {
    let res = await instance.get("/api/v2/symbols/?size=500");
    return res;
  } catch (error) {
    throw error;
  }
};

export const clientData = async (data, size , page) => {
  try {
    // const data = {
    //   "body" : dataIn ,
    //  }
    //  const config = {
    //   headers: {

    //    Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImQyNzE3YWZkLTI4N2EtNDEyZS04NGQxLWI1ZDdmZTNkMjNlNyIsImVtYWlsIjoiYW1pdHMua2VldkBnbWFpbC5jb20iLCJyb2xlIjoic3VwZXJfYWRtaW4iLCJzdWIiOiJkMjcxN2FmZC0yODdhLTQxMmUtODRkMS1iNWQ3ZmUzZDIzZTciLCJleHAiOjE2NzU3NjU1MDMsImlhdCI6MTY3NTc2MTkwMywianRpIjoiN2RiMDk1MGUtZWRiNS00ZGNiLWFiYjQtYjJlMjQ3MThmZmI1In0.1R6rKUq2FTrSr8n1mtVwVZhYnrdFXhVwSio-Q6r1bHA',
    //  }}

    // const res = await instance.request("api/v2/orders/dashboard_filter", "GET" ,data);
    // const res = await axios.get("https://demo.arjunconsulting.in/api/v2/orders/dashboard_filter", data , config);
    let a = false;
    let url = ``;
    if (data.email !== undefined) {
      url = url + `email=${data.email}`;
      a = true;
    }
    if (data.strategy_name !== undefined) {
      if (a === true) {
        url = url + `&`;
      }
      url = url + `strategy_name=${encodeURIComponent(data.strategy_name)}`;
      a = true;
    }
    if (data.status !== undefined) {
      if (a === true) {
        url = url + `&`;
      }
      url = url + `status=${data.status}`;
      a = true;
    }

    if (data.startdate !== undefined && data.enddate !== undefined) {
      if (a === true) {
        url = url + `&`;
      }
      url = url + `start_date=${data.startdate}&end_date=${data.enddate}`;
    }

    const res = await instance.get(
      `/api/v2/orders/dashboard/?size=${size}&page=${page}&${url}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const randomUrlCall = async (data) => {
  try {
    // const res = await instance.post("/api/v2/orders/update_pending_status", data);
    // return res;
  } catch (error) {
    throw error;
  }
};

export const pendingstatus = async (data) => {
  try {
    const res = await instance.post(`/api/v2/orders/update_pending_status`);
    return res;
  } catch (error) {
    throw error;
  }
};

// get Indivisual Client Data
export const indivisualClientData = async (
  email,
  size = 10,
  page = 0,
  data,
  name
) => {
  try {
    // apply filters conditionally

    let url = ``;
    if (data !== null) {
      let a = false;

      if (data.status !== undefined) {
        url = url + "&";
        url = url + `status=${data.status}`;
        a = true;
      }
      if (data.startdate !== undefined && data.enddate !== undefined) {
        url = url + "&";
        url = url + `start_date=${data.startdate}&end_date=${data.enddate}`;
      }
    }

    if (email !== undefined) {
      const res = await instance.get(
        name === "client"
          ? `api/v2/orders/dashboard?email=${email}&size=${size}&page=${page}${url}`
          : name === "strategy"
          ? `api/v2/orders/dashboard?strategy_name=${email}&size=${size}&page=${page}${url}`
          : `api/v2/orders/dashboard?portfolio_id=${email}&size=${size}&page=${page}${url}`
      );

      return res;
    }
  } catch (error) {
    throw error;
  }
};

// pending order
export const PendingOrdersData = async (data) => {
  try {
    const res = await instance1.post(
        
      `/api/v1/reports/pending`,
      data
      
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const get_Error_Rejected_count = async () => {
  try {
    const res = await instance.get(`/api/v2/orders/count_error_request`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const filter_Error_Rejected = async (data) => {
  try {
    const res = await instance1.post(`api/v1/reports/order/aggregate`,data);
    return res;
  } catch (error) {
    throw error;
  }
};
