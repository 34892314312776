import { configureStore } from "@reduxjs/toolkit";
import modal from "./features/modal";

const store = configureStore({
  reducer: {
    modal,
  },
});

export default store;
