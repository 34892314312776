import { Box, Flex, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import {
  detailsCard,
  get_Error_Rejected_count,
  openPositionCount,
} from "apis/dashboard";
import { GoStop } from "react-icons/go";
import { useEffect, useState, lazy, Suspense } from "react";
import { AiOutlineStop } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import urls from "utils/routes";
import theme from "./utils/theme";
import {
  MdCheckCircle,
  MdCancel,
  MdOutlineError,
  MdPending,
} from "react-icons/md";
import { TbReport } from "react-icons/tb";
import ClientstatusIcon from "assets/icons/ClientstatusIcon";
import OrderStatusIcon from "assets/icons/OrderStatusIcon";
import BrokerRecordStatusIcon from "assets/icons/BrokerRecordStatusIcon";
const ActiveUserIcon = lazy(() => import("assets/icons/ActiveUserIcon"));
const DeletedUserIcon = lazy(() => import("assets/icons/DeletedUserIcon"));
const PLIcon = lazy(() => import("assets/icons/PLIcon"));
const PausedUserIcon = lazy(() => import("assets/icons/PausedUserIcon"));
const TradeHistoryIcon = lazy(() => import("assets/icons/TradeHistoryIcon"));
const TradegraphIcon = lazy(() => import("assets/icons/TradegraphIcon"));

const Cardsdashboad = () => {
  const brand = theme.colors.brand[400];
  const location = useLocation();
  const navigate = useNavigate();
  const [activeuser, setAtive] = useState(0);
  const [pauseuser, setPause] = useState(0);
  const [Discarduser, setDiscard] = useState(0);
  const [pending, setPending] = useState(0);
  const [positions, setPositions] = useState(0);
  const [error, setError] = useState(0);

  const pathname = window.location.pathname;
  useEffect(() => {
    dashboarddata();
  }, [location]);

  const dashboarddata = async () => {
    try {
      const [detailsCardResponse, ErrorRejResponse, openPositionCountResponse] =
        await Promise.all([detailsCard()]);

      const {
        open_positions,
        pending_orders,
        error_orders,
        active_clients,
        paused_clients,
        ...rest
      } = detailsCardResponse.data[0];
      localStorage.setItem("active_user_size", active_clients);
      setAtive(active_clients);

      setPause(paused_clients);
      // setDiscard(discard_client);
      setPending(pending_orders);
      setPositions(open_positions);
      setError(error_orders);
    } catch (error) {
      console.error(error);
    }
  };

  if (
    location.pathname == "/Market-Place" ||
    location.pathname == "/strategy-details"
  ) {
    return null;
  }

  return (
    <Box ml="20px" mt="2" w="98%" zIndex={"18"}>
      <Grid templateColumns="repeat(3, 1fr)" gap="4%" w="100%">
        <GridItem h="100%">
          <Flex
            w="100%"
            h="100%"
            alignItems={"center"}
            position="relative"
            flexDirection={"column"}
            ml="0"
          >
            <Flex
              width={{ base: "80%", md: "50%", lg: "33%" }}
              height="23px"
              flexShrink="0"
              borderRadius="0.3125rem"
              background="brand.900"
              position="relative"
              display="flex"
              alignItems="center"
              zIndex={"10"}
              justifyContent={"center"}
            >
              <Text
                color="brand.500"
                display="flex"
                alignItems="center"
                gap="1"
                justifyContent={"center"}
                fontSize="0.75rem"
                borderColor="transparent"
                fontWeight="700"
                lineHeight="normal"
                textAlign={"center"}
                w="100%"
              >
                <OrderStatusIcon />
                Order Status
              </Text>
            </Flex>
            <Box
              w="65%"
              h="58px"
              flexShrink="0"
              borderRadius="0.625rem 0.625rem 0rem 0.625rem"
              bg="brand.200"
              position="absolute"
              zIndex={4}
              top="3"
            ></Box>
            <Flex
              w="100%"
              h="60%"
              position="absolute"
              top="10"
              justifyContent={"space-between"}
            >
              <Box
                // width={{ base: '60px', md: '80px', lg: '100px' }}
                w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                // border="1px solid gray"
                onClick={() => {
                  navigate(urls.openRequest);
                  setTimeout(() => {
                    window.location.reload();
                  }, 0);
                  // window.location.reload();
                }}
              >
                <Flex
                  marginTop={"2"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <TradegraphIcon width="30px" height="30px" />
                </Flex>
                <Box w="50%" m="auto">
                  <Text
                    mt="1"
                    borderRadius={4}
                    // width="0.8125rem"
                    height="1.4rem"
                    flex-shrink="0"
                    bordeRadius="0.0625rem"
                    background="brand.600"
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    color="brand.700"
                    fontSize="1.0125rem"
                    borderColor="transparent"
                    fontWeight="700"
                    lineHeight="normal"
                    marginBottom={"1"}
                  >
                    {positions}
                  </Text>
                </Box>
                <Text
                  textAlign="center"
                  color="brand.600"
                  borderColor="transparent"
                  fontWeight="700"
                  fontSize="11px"
                  fontStyle="normal"
                >
                  Open{" "}
                </Text>
              </Box>
              <Box
                // width={{ base: '60px', md: '80px', lg: '100px' }}
                w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                // border="1px solid gray"
                onClick={() => navigate("/Pendingorders")}
              >
                <Flex
                  marginTop={"2"}
                  alignItems={"center"}
                  justifyContent={"center"}

                  // mt="6"
                >
                  <Icon color="brand.600" f w="25px" h="25px" as={MdPending} />
                </Flex>

                <Box w="50%" m="auto">
                  <Text
                    mt="2"
                    borderRadius={4}
                    // width="0.8125rem"
                    height="1.4rem"
                    flex-shrink="0"
                    bordeRadius="0.0625rem"
                    background="brand.600"
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    color="brand.700"
                    borderColor="transparent"
                    fontWeight="700"
                    fontSize="1.0125rem"
                    fontStyle="normal"
                    lineHeight="normal"
                    // fontFamily: DM Sans;
                    marginBottom={"1"}
                  >
                    {pending}
                  </Text>
                </Box>
                <Text
                  textAlign="center"
                  color="brand.600"
                  borderColor="transparent"
                  fontWeight="700"
                  fontSize="11px"
                  fontStyle="normal"
                >
                  Pending{" "}
                </Text>
              </Box>
              <Box
                // width={{ base: '60px', md: '80px', lg: '100px' }}
                w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                // border="1px solid gray"
                onClick={() => navigate("/Errororders")}
              >
                <Flex
                  marginTop={"2"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Icon color="brand.600" f w="25px" h="25px" as={MdCancel} />
                </Flex>

                <Box w="50%" m="auto">
                  <Text
                    mt="3"
                    borderRadius={4}
                    // width="0.8125rem"
                    height="1.4rem"
                    flex-shrink="0"
                    bordeRadius="0.0625rem"
                    background="brand.600"
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    color="brand.700"
                    borderColor="transparent"
                    fontWeight="700"
                    fontSize="1.0125rem"
                    marginBottom={"1"}
                    // fontFamily: DM Sans;
                  >
                    {error}
                  </Text>
                </Box>
                <Text
                  textAlign="center"
                  color="brand.600"
                  borderColor="transparent"
                  fontWeight="700"
                  fontSize="11px"
                  fontStyle="normal"
                >
                  Error{" "}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem h="100%">
          <Flex
            w="100%"
            h="150px"
            alignItems={"center"}
            position="relative"
            flexDirection={"column"}
          >
            <Flex
              width={{ base: "80%", md: "50%", lg: "33%" }}
              height="23px"
              flexShrink="0"
              borderRadius="0.3125rem"
              background="brand.900"
              position="relative"
              alignItems="center"
              zIndex={"10"}
              justifyContent={"center"}
            >
              <Text
                color="brand.500"
                zIndex={"10"}
                fontSize="0.75rem"
                fontStyle="normal"
                borderColor="transparent"
                fontWeight="700"
                lineHeight="normal"
                display="flex"
                alignItems="center"
                gap="1"
                justifyContent={"center"}
              >
                <ClientstatusIcon />
                Clients Status
              </Text>
            </Flex>
            <Box
              w="65%"
              h="58px"
              flexShrink="0"
              borderRadius="0.625rem 0.625rem 0rem 0.625rem"
              bg="brand.200"
              position="absolute"
              zIndex={4}
              top="3"
            ></Box>
            <Box
              w="100%"
              h="60%"
              position="absolute"
              top="10"
              display="flex"
              // justifyContent="space-evenly"
              justifyContent={"space-between"}
            >
              <Box
                // width={{ base: '60px', md: '80px', lg: '100px' }}
                w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                // border="1px solid gray"
                onClick={() => {
                  //   handleactive()
                  navigate("/clients", { state: { api_status: "active" } });
                }}
              >
                <Box
                  marginTop={"2"}
                  display="flex"
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <ActiveUserIcon width="30px" height="30px" />
                </Box>
                <Box w="50%" m="auto">
                  <Text
                    mt="1"
                    borderRadius={4}
                    // width="0.8125rem"
                    height="1.4rem"
                    flex-shrink="0"
                    bordeRadius="0.0625rem"
                    background="brand.600"
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    color="brand.700"
                    borderColor="transparent"
                    fontWeight="700"
                    fontSize="1.0125rem"
                    fontStyle="normal"
                    marginBottom={"1"}
                    lineHeight="normal"
                    // fontFamily: DM Sans;
                  >
                    {activeuser}
                  </Text>
                  {/* <Text
                    fontSize={"13px"}
                    position="absolute"
                    bottom={0}
                    right="3"
                    padding={0}
                    fontFamily="Darker Grotesque"
                    fontStyle="normal"
                    color="#4D4DFF"
                    textAlign="center"
                    fontWeight={400}
                  >
                    Open{" "}
                  </Text> */}
                </Box>
                <Text
                  textAlign="center"
                  color="brand.600"
                  borderColor="transparent"
                  fontWeight="700"
                  fontSize="11px"
                  fontStyle="normal"
                >
                  Active{" "}
                </Text>
              </Box>
              <Box
                // width={{ base: '60px', md: '80px', lg: '100px' }}
                w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                // border="1px solid gray"
                onClick={() => {
                  navigate("/clients", { state: { api_status: "pause" } });
                }}
              >
                <Box
                  marginTop={"2"}
                  display="flex"
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <PausedUserIcon width="30px" height="30px" />
                </Box>
                <Box w="50%" m="auto">
                  <Text
                    mt="1"
                    borderRadius={4}
                    // width="0.8125rem"
                    height="1.4rem"
                    flex-shrink="0"
                    bordeRadius="0.0625rem"
                    background="brand.600"
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    color="brand.700"
                    borderColor="transparent"
                    fontWeight="700"
                    fontSize="1.0125rem"
                    marginBottom={"1"}

                    // fontFamily: DM Sans;
                  >
                    {pauseuser}
                  </Text>
                  {/* <Text
                    fontSize={"13px"}
                    position="absolute"
                    bottom={0}
                    right="3"
                    padding={0}
                    fontFamily="Darker Grotesque"
                    fontStyle="normal"
                    color="#4D4DFF"
                    textAlign="center"
                    fontWeight={400}
                  >
                    Open{" "}
                  </Text> */}
                </Box>
                <Text
                  textAlign="center"
                  color="brand.600"
                  borderColor="transparent"
                  fontWeight="700"
                  fontSize="11px"
                  fontStyle="normal"
                >
                  Paused{" "}
                </Text>
              </Box>
              <Flex
                // width={{ base: '60px', md: '80px', lg: '100px' }}
                w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                alignItems={"center"}
                justifyContent={"center"}
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                onClick={() => {}}
              >
                <Box display={"flex"} justifyContent={"center"}>
                  <GoStop fontSize={"55px"} />
                </Box>
                {/* <Box  m="auto" w="50%" > */}

                {/* <Text
                  m="auto"
                  width={{ base: "100%", md: "80%", lg: "60%" }}
                  color="brand.600"
                  textAlign="center"
                  fontSize="11px"
                  borderColor="transparent"
                  fontWeight="700"
                >
               
                </Text> */}
                {/* </Box> */}
              </Flex>
            </Box>
          </Flex>
        </GridItem>
        <GridItem h="100%">
          <Flex
            w="100%"
            h="150px"
            alignItems={"center"}
            position="relative"
            flexDirection={"column"}
          >
            <Box
              width={{ base: "80%", md: "50%", lg: "33%" }}
              height="23px"
              flexShrink="0"
              borderRadius="0.3125rem"
              background="brand.900"
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent={"center"}
              // zIndex={"10"}
            >
              <Text
                color="brand.500"
                borderColor="transparent"
                fontWeight="700"
                fontSize="0.75rem"
                fontStyle="normal"
                display="flex"
                alignItems="center"
                gap="1"
                justifyContent={"center"}
                lineHeight="normal"
              >
                <BrokerRecordStatusIcon />
                Records
              </Text>
            </Box>
            <Box
              w="65%"
              h="58px"
              flexShrink="0"
              borderRadius="0.625rem 0.625rem 0rem 0.625rem"
              bg="brand.200"
              position="absolute"
              zIndex={4}
              top="3"
            ></Box>
            <Box
              w="100%"
              h="60%"
              position="absolute"
              top="10"
              display="flex"
              // justifyContent="space-evenly"
              justifyContent={"space-between"}
            >
              <Box
                // width={{ base: '60px', md: '80px', lg: '100px' }}
                w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                onClick={() => {
                  //   handleactive()
                  navigate(urls.tradehistory);
                  setTimeout(() => {
                    window.location.reload();
                  }, 0);
                
                }}
              >
                <Box display={"flex"} mt="1" justifyContent={"center"}>
                  <TradeHistoryIcon width="42px" height="42px" />
                </Box>
                {/* <Box  m="auto" w="50%" > */}

                <Text
                  m="auto"
                  width={{ base: "100%", md: "80%", lg: "50%" }}
                  color="brand.600"
                  textAlign="center"
                  borderColor="transparent"
                  fontWeight="700"
                  fontSize="11px"
                  fontStyle="normal"
                >
                  Trade History
                </Text>
                {/* </Box> */}
              </Box>
              <Box
                // width={{ base: '60px', md: '80px', lg: '100px' }}
                w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                onClick={() => {
                  //   handleactive()
                  navigate("/P&L");
                }}
              >
                <Box display={"flex"} mt="1" justifyContent={"center"}>
                  <PLIcon width="42px" height="42px" />
                </Box>
                {/* <Box  m="auto" w="50%" > */}

                <Text
                  m="auto"
                  width={{ base: "100%", md: "80%", lg: "60%" }}
                  color="brand.600"
                  textAlign="center"
                  fontSize="11px"
                  fontStyle="normal"
                  borderColor="transparent"
                  fontWeight="700"
                >
                  P&L Statement
                </Text>
                {/* </Box> */}
              </Box>
              <Box
              
                w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                alignItems={"center"}
                justifyContent={"center"}
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                onClick={() => {
                  //   handleactive()
                  navigate("/Reports");
                }}
              >
                <Box display={"flex"} justifyContent={"center"}>
                  <TbReport fontSize={"60px"} />
                </Box>
               

                <Text
                  m="auto"
                  width={{ base: "100%", md: "80%", lg: "60%" }}
                  color="brand.600"
                  textAlign="center"
                  fontSize="11px"
                  borderColor="transparent"
                  fontWeight="700"
                >
               Reports
                </Text>
               
              </Box>
             
            </Box>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Cardsdashboad;
