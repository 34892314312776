import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  label: {
    fontFamily: "lato",
  },
  control: {
    borderRadius: "50%",
    border: "1.5px solid",
    borderColor: "#CCC",
    backgroundColor: "#ffffff",
    color: "black",
    _checked: {
      borderColor: "#000",
      backgroundColor: "#ffffff",
      color: "#000",
      _hover: {
        backgroundColor: "#ffffff",
        borderColor: "#000",
      },
    },
    _hover: {
      backgroundColor: "#ffffff",
    },
    _focus: {
      outline: "none",
      backgroundColor: "#ffffff",
    },
  },
});

const sizes = {
  // define custom styles for xl size
  xl: definePartsStyle({
    control: { w: "10", h: "10" },
    label: { fontSize: "xl" },
  }),
};

export const radioTheme = defineMultiStyleConfig({ baseStyle, sizes });
