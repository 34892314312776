import React from 'react'
import theme from "../../utils/theme"
import { Box } from '@chakra-ui/react'
const PortfolioIcon = ({ height, width ,color}) => {
  
  return (
    <Box display="flex" alignItems={"center"} >
    
    
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" fill="none">
    <path d="M3.25415 0.5L7.76242 0.5V2.49731L3.25415 2.49731L3.25415 0.5Z" fill="white" stroke={color}/>
    <rect x="0.5" y="2.69922" width="10.2018" height="7.91636" rx="0.5" stroke={color}/>
    <path d="M1 8H11V10C11 10.5523 10.5523 11 10 11H1V8Z" fill={color}/>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 4 6" fill="none">
  <path d="M1 1L3 3L1 5" stroke={color}/>
</svg>
    </Box>
  )
}

export default PortfolioIcon