import { Icon } from '@chakra-ui/react'
import React from 'react'
import theme from "utils/theme"
const ClientstatusIcon = () => {
    const brand=theme.colors.brand[400]
  return (
    <Icon
               
                width="17px"
                height="14px"
                viewBox="0 0 17 14"
                fill="none"
              >
                <rect
                  x="1"
                  y="6.63672"
                  width="7"
                  height="5.21214"
                  rx="2"
                  fill="#A3AED0"
                  stroke="#A3AED0"
                  stroke-width="2"
                />
                <path
                
                  fill="#A3AED0"
                  stroke="#A3AED0"
                  stroke-width="2"
                />
                <rect
                  x="1"
                  y="6.63672"
                  width="7"
                  height="5.21214"
                  rx="1"
                  fill={brand}
                  stroke="white"
                  stroke-width="2"
                />
                <path
                  d="M6.60762 3.09123C6.60762 4.20232 5.6495 5.18247 4.36729 5.18247C3.08507 5.18247 2.12695 4.20232 2.12695 3.09123C2.12695 1.98015 3.08507 1 4.36729 1C5.6495 1 6.60762 1.98015 6.60762 3.09123Z"
                  fill={brand}
                  stroke={brand}
                  stroke-width="2"
                />
                <rect y="9.82031" width="9" height="3.27273" fill={brand} />
                <path
                  d="M0.818115 7.54687C0.818115 6.99459 1.26583 6.54688 1.81812 6.54688H7.18175C7.73404 6.54688 8.18175 6.99459 8.18175 7.54688V10.6378H0.818115V7.54687Z"
                  fill={brand}
                />
                <path
                  d="M13.5 2.09375L14.2858 4.51219H16.8287L14.7714 6.00687L15.5572 8.42531L13.5 6.93063L11.4428 8.42531L12.2286 6.00687L10.1713 4.51219H12.7142L13.5 2.09375Z"
                  fill={brand}
                />
              </Icon>
  )
}

export default ClientstatusIcon