import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Text,
  Td,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { closeapi, sendOpenPositions } from "apis/OpenReuest";
import { clientIsActive } from "apis/client";
import { clientData } from "apis/dashboard";

const headings = [
  "Strategy",
  "Symbol",
  "product Type",
  "Completed Order",
  "Rejected Order",
  "Unrealized P&L",
  "Realized P&L",
];
const UserPL = (props) => {
  const { setIsCompletedOrderModal, isOpen, onClose, modaldata, userId } =
    props;
  const toast = useToast();
  function customSort(a, b) {
    // Check if the today_transaction_type is empty for both objects
    const isEmptyA = a.today_transaction_type === "";
    const isEmptyB = b.today_transaction_type === "";

    // Sort based on whether the today_transaction_type is empty or not
    if (isEmptyA && isEmptyB) {
      return 0; // No change in order for two empty values
    } else if (isEmptyA) {
      return 1; // Move empty value to the end
    } else if (isEmptyB) {
      return -1; // Move empty value to the beginning
    } else {
      // Compare non-empty values
      return a.today_transaction_type.localeCompare(b.today_transaction_type);
    }
  }

  const handleSquareOff = async (markettype) => {
    try {
      // Retrieve the role from local storage or use an empty string if not present
      const role = localStorage.getItem("role") || "";

      // Create the payload array by mapping over modaldata
      const data = modaldata
        .filter((obj) => obj.today_transaction_type !== "") // Remove objects with empty today_transaction_type
        .sort((a, b) =>
          a.today_transaction_type.localeCompare(b.today_transaction_type)
        );

      const payload = data.map(
        ({
          symbol,
          segment,
          exchange,
          product_type,
          secType,
          expiry,
          strike,
          right,
        }) => ({
          close_percent: parseFloat(100),
          exchange,
          expiry,
          order_type: markettype,
          order_action: "PNL",
          product_type,
          right,
          role,
          secType,
          segment,
          strategy_name: "",
          strike,
          symbol,
        })
      );
      payload[data.length - 1]["pause"] = "pause";

      // Create payload2 object
      const payload2 = { client_email: userId, is_active: "0" };

      // Call closeapi and clientIsActive asynchronously
      await closeapi(payload);
      setTimeout(async () => {
        await clientIsActive(payload2);
      }, 3000);

      // Show a toast notification for success
      toast({
        title: "Square off successful!",
        status: "success",
        position: "top-right",
        duration: 5000, // Duration the toast will be displayed (in milliseconds)
        isClosable: true,
      });
      setIsCompletedOrderModal(false);
    } catch (error) {
      // Log any errors that occur during the asynchronous operations
      console.error("Error in handleSquareOff:", error);
      setIsCompletedOrderModal(false);
      // Show a toast notification for the error
      toast({
        title: "An error occurred during square off.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"6xl"}>
      <ModalOverlay />
      <ModalContent
        bg="white"
        height="auto"
        borderTopRadius={"18"}
        borderBottomLeftRadius={"18"}
        borderBottomRightRadius={"18"}
      >
        <ModalHeader
          borderTopRightRadius={"18"}
          borderTopLeftRadius={"18"}
          color="brand.500"
          fontWeight="700"
          bg={"brand.900"}
        >
          <HStack gap="10%">
            <Text>P&L </Text> <Text>Client Code : {modaldata[0]?.client_id}</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton onClick={() => setIsCompletedOrderModal(false)} />
        <ModalBody overflow={"auto"}>
          <TableContainer
            height="390px"
            maxHeight="410px"
            my={4}
            border="1px solid #eef3f4"
            overflowY="auto"
          >
            <Table variant="custom">
              <Thead>
                <Tr bg={"brand.900"}>
                  {headings.map((item, index) => {
                    return (
                      <Th
                        color={"brand.500"}
                        fontWeight="700"
                        borderRight="2px solid white"
                        borderTopLeftRadius={index === 0 && "15px"}
                        key={index}
                      >
                        {item}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>

              <Tbody bg="white">
                {modaldata?.length > 0 ? (
                  modaldata?.map((item, index) => {
                    return (
                      <Tr
                        key={index}
                        height="50px"
                        textAlign="start"
                        verticalAlign="center"
                        display=""
                        fontSize="15px"
                        fontWeight="700"
                      >
                        <Td>{item?.strategy_name}</Td>
                        <Td>{item?.datafeed_symbol}</Td>
                        <Td>{item?.producttype}</Td>
                        <Td py="6px">{item?.completed}</Td>
                        <Td py="6px">{item?.rejected}</Td>
                        <Td
                          py="6px"
                          // color={
                          //   item.unrealized_pnl < 0 ? "#E90303" : "#008000"
                          // }
                          color={
                            item?.unrealized_pnl < 0
                              ? "red"
                              : item?.unrealized_pnl > 0
                              ? "green"
                              : "black"
                          }
                        >
                          {item?.unrealized_pnl?.toLocaleString(
                              "en-IN",
                              {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }
                            )}
                        </Td>
                        <Td
                          py="6px"
                          color={
                            item?.realized_pnl < 0
                              ? "red"
                              : item?.realized_pnl > 0
                              ? "green"
                              : "black"
                          }
                        >
                          {item?.realized_pnl?.toLocaleString(
                              "en-IN",
                              {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }
                            )}
                        </Td>
                      </Tr>
                    );
                  })
                ) : (
                  <HStack justify="center" alignItems="center" p={2}>
                    <Text textAlign="center">No data Available</Text>
                  </HStack>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter
          borderBottomLeftRadius={"18"}
          borderBottomRightRadius={"18"}
        >
          <HStack>
            {/* <Button
              variant="ghost"
              bg={"brand.900"}
              color={process.env.REACT_APP_MODAL_TITLE_COLOR}
              onClick={() => handleSquareOff("MARKET")}
            >
              Square off By MARKET
            </Button>
            <Button
              variant="ghost"
              bg={"brand.900"}
              color={process.env.REACT_APP_MODAL_TITLE_COLOR}
              onClick={() => handleSquareOff("LIMIT")}
            >
              Square off By LIMIT
            </Button> */}
            <Button
              variant="ghost"
              bg={"brand.900"}
              color={process.env.REACT_APP_MODAL_TITLE_COLOR}
              onClick={() => setIsCompletedOrderModal(false)}
            >
              Close
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserPL;
