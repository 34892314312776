import withRouter from "hoc/withRouter";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isTokenExpired } from "./utils";

const PrivateRoute = ({ children, router }) => {
  const token = localStorage.getItem("accessToken");
  useEffect(() => {
    if (token !== null && !isTokenExpired(token)) {
      localStorage.removeItem("accessToken");
      router.navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  return token ? <Outlet /> : <Navigate to="/" />;
};

export default withRouter(PrivateRoute);
