import instance from "utils/interceptor";
import instance1 from "utils/interceptor1";

export const getOpenRequest = async (data = {}, page = 0, size = 10) => {
  try {
    let url = ``;
    if (data.email !== undefined) {
      url = url + `&client=${data.email}`;
    }
    if (data.strategy !== undefined) {
      url = url + `&strategy=${encodeURIComponent(data.strategy)}`;
    }

    if (data.portfolio !== undefined) {
      url = url + `&portfolio=${data.portfolio}`;
    }
    if (data.admin !== undefined) {
      url = url + `&admin=${data.admin}`;
    }
    const res = await instance.get(
      `/api/v2/orders/open_requests/?page=${page}&size=${size}` + url
      // `/api/v2/orders/open_requests?` + url
    );

    return res;
  } catch (error) {
    throw error;
  }
};

export const getFilterPosition = async (data) => {
  try {
    const res = await instance1.post(
      `/api/v1/reports/open-position/merged-open-position-by-fields/`,
     data
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getPositionFilterOptions = async () => {
  try {
    const res = await instance1.get(`/api/v1/reports/open-position/aggregate`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getPosition = async () => {
  try {
    const res = await instance1.get(`/api/v1/reports/open-position/`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getOpenRequestForAll = async (data) => {
  try {
    const res = await instance1.post(`/api/v1/reports/open-position/tws`,data);
    return res;
  } catch (error) {
    throw error;
  }
};
export const getOpenPositionsAll = async (data) => {
  try {
    const res = await instance1.post(`/api/v1/reports/open-position/open-close-filter`,data);
    return res;
  } catch (error) {
    throw error;
  }
};
export const removefromopenPosition = async (data) => {
  try {
    const res = await instance.post(
      `/api/v2/orders/open_requests-remove`,
      data
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const removeErrorOrders = async (data) => {
  try {
    const res = await instance.post(`/api/v2/orders/error_orders-remove`, data);
    return res;
  } catch (error) {
    throw error;
  }
};

// encodeURIComponent(data.strategy_name)

export const sendOpenPositions = async (data, filter, isFilterApply) => {
  try {
    let url = "/api/v2/orders/close";

    if (isFilterApply) {
      const queryParams = [];

      if (filter.strategy !== undefined) {
        queryParams.push(
          `strategy_name=${encodeURIComponent(filter.strategy)}`
        );
      }
      if (filter.email !== undefined) {
        queryParams.push(`keev_email_id=${filter.email}`);
      }
      if (filter.portfolio !== undefined) {
        queryParams.push(`portfolio_id=${filter.portfolio}`);
      }
      if (filter.admin !== undefined) {
        queryParams.push(`managed_by=${filter.admin}`);
      }

      if (queryParams.length > 0) {
        url += "?" + queryParams.join("&");
      }

      const res = await instance.post(url, data);
      return res;
    } else {
      const res = await instance.post(url, data);
      return res;
    }
  } catch (error) {
    throw error;
  }
};


export const openPositionSquareOff = async (data) => {
  try {
    let url = "api/v1/reports/open-position/square-off";

    const res = await instance1.post(url, data);
    return res;
  } catch (error) {
    throw error;
  }
};
export const closeapi = async (data) => {
  try {
    let url = "/api/v2/orders/close";

    const res = await instance.post(url, data);
    return res;
  } catch (error) {
    throw error;
  }
};
// export const sendOpenPositions = async (data, filter, isFilterApply) => {
//   try {
//     // if (isFilterApply) {
//     //   let url = ``;
//     //   if (filter.email !== undefined) {
//     //     url = url + `&keev_email_id=${filter.email}`;
//     //   }

//     //   if (filter.portfolio !== undefined) {
//     //     url = url + `&portfolio_id=${filter.portfolio}`;
//     //   }
//     //   if (filter.admin !== undefined) {
//     //     url = url + `&managed_by=${filter.admin}`;
//     //   }
//     //   const res = await instance.get(
//     //     `/api/v2/orders/close?` + url
//     //   );
//     //   return res;
//     // }
//     if (isFilterApply) {
//       let url = `/api/v2/orders/close?`;
//       if (filter.strategy !== undefined) {
//         url = url + `strategy_name=${filter.strategy}`;
//         if (
//           filter.email !== undefined ||
//           filter.portfolio !== undefined ||
//           filter.admin !== undefined
//         ) {
//           url = url + "&";
//         }
//       }

//       if (filter.email !== undefined) {
//         url = url + `keev_email_id=${filter.email}`;

//         if (filter.portfolio !== undefined || filter.admin !== undefined) {
//           url = url + "&";
//         }
//       }

//       if (filter.portfolio !== undefined) {
//         url = url + `portfolio_id=${filter.portfolio}`;

//         if (filter.admin !== undefined) {
//           url = url + "&";
//         }
//       }

//       if (filter.admin !== undefined) {
//         url = url + `managed_by=${filter.admin}`;
//         if(filter.email === undefined ||
//           filter.portfolio === undefined ||
//           filter.admin === undefined){
//             url = url + `managed_by=${filter.admin}`;
//           }
//       }

//       const res = await instance.post(url, data);
//       return res;
//     } else {
//       const res = await instance.post("/api/v2/orders/close", data);
//       return res;
//     }
//   } catch (error) {
//     throw error;
//   }
// };

// open_position place order
export const OpenPositionPlaceOrder = async (data) => {
  try {
    const res = await instance.post("/api/v2/orders/close-open-postions", data);
    return res;
  } catch (error) {
    throw error;
  }
};

// get admin list
export const getAdminList = async (page = 0, size = 50) => {
  try {
    const res = await instance.get(
      `/api/v2/users?page=${page}&size=${size}&role=admin`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

// download open request data

export const getOpenPositions = async () => {
  try {
    const res = await instance.get(`/api/v2/orders/open-postions-csv`);

    return res;
  } catch (error) {
    throw error;
  }
};

// client Wise:- Order Sync

export const ClientOrdersSync = (data) => {
  try {
    const res = instance1.post(`/api/v1/reports/open-position/fields/`, data);
    return res;
  } catch (error) {
    throw error;
  }
};
export const ClientOrdersbrokermap = (data) => {
  try {
    const res = instance.post(`/api/v2/orders/broker_qty_client_data`, data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const ClientErrorOrders = async (data) => {
  try {
    let res = await instance.post(`/api/v2/orders/open_requests-errors`, data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const ClientCompletedOrders = async (data) => {
  try {
    let res = await instance.post(
      // `/api/v2/orders/open_requests-completed`,
      `/api/v2/orders/open_requests-client-completed`,
      data
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getBrokerQty = async () => {
  try {
    let res = await instance.get(`/api/v2/orders/fetch_broker_qty`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getBrokersQuantity = async () => {
  try {
    let res = await instance.get(`/api/v2/orders/broker_qty_clients`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getclient_count = async (data) => {
  try {
    let res = await instance.post(
      `/api/v2/orders/open_requests-client-count`,
      data
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const reorderattemp = async (data) => {
  try {
    let res = await instance.post(
      `/api/v2/orders/error_requests_attempt`,
      data
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const markComplete = async (data) => {
  try {
    let res = await instance.post(
      `/api/v2/orders/error_requests_mark_completed`,
      data
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getBrokerMappingdata = async () => {
  try {
    let res = await instance.get(`/api/v2/orders/broker_qty_data`);
    return res;
  } catch (error) {
    throw error;
  }
};
